body {
  background-color: #F5F5F6;
}

#_body{
  margin: 0 auto;
  width: 95%;
  text-align: center;
  height: 100vh;
  margin-top:10px;
  background-color: #F5F5F6;
  font-family: Arial, Helvetica, sans-serif;
}

.main {
  position: relative;
  width: 100%;
  padding-bottom:30px;
  padding-top:10px;
}

.required:after {
  content:" *";
  color: red;
}

.visitorForm {
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  width:100%;
}

.form_group {
  display: flex;
  flex-direction: column;
}

.field {
  padding-top: 5px;
  padding-bottom: 5px;
}

input {
  padding-bottom: 10px;
}

.btnSubmit {
  padding-top: 10px;
  padding-bottom: 10px;
}

h1 {
  font-size: 35px;
  color: #1B1A1A;
}

p {
  font-size: 14px;
  color: #323233;
}

/*Ensures the semantic ui dimmer covers the whole page*/
.ui.dimmer {
  position: fixed;
}
.ui.scrolling.dimmable > .dimmer,
.ui.scrolling.page.dimmer {
position: fixed; 
}
/* Bound to segment size*/
.ui.segment > .ui.dimmer {
position:absolute;
}


/* $bp-small:24em;
$bp-small-2:29.75em;
$bp-small-3:39.8em;
$bp-med:46.8em;
$bp-med-2:55em;
$bp-large:65em;
$bp-large-2:70em;
$bp-xl:85em;
$bp-xl-2:100em; */
@media all and (max-width: 24em){
    #_body{
      width: 95%;
  }
}

@media (min-width: 24em) and (max-width: 46.8em){
  #_body{
    width: 95%;
  }
}

@media (min-width: 46.8em) and (max-width: 65em){
  #_body{
    width: 60%;
  }
}

@media (min-width: 65em) and (max-width: 85em){
  #_body{
    width: 60%;
  }
}

@media (min-width: 85em){
  #_body{
    width: 60%;
  }
}

